@use "src/utils/mixins/flex" as *;

.layout {
  .wrapper {
	@include flexible(row, center, center);

	min-height: 100vh;

	.content {
	  width: 50%;
	}
  }
}
