$title: #252525;
$middle: #484848;
$low: #666666;

$border: #dfdfdf;
$hover: #ff9a00;
$back: #fafafa;

$shop: #fc3636;
$event: #07adf6;
$discount: #428b15;
