@use "../../utils/mixins/flex" as *;
@use "../../utils/constants/sizes" as *;
@use "../../utils/constants/colors" as *;

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;

  @media screen and (max-width: $medium) {
	height: 300px;
  }
}