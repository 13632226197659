@use "/src/utils/mixins/flex" as *;
@use "/src/utils/constants/colors" as *;

.wrapper {
  width: 100%;

  background: $back;
  border: 2px solid $low;
  transition: border 0.3s ease-in-out;

  .content {
    padding: 5px 10px 5px 10px;
  }
}

.readonly {
  width: 100%;
}