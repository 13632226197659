@use "../../utils/constants/colors" as *;
@use "../../utils/constants/sizes" as *;

.default {
  text-decoration: none;
}

.underlined {
  text-decoration: none;
  cursor: pointer;

  line-height: 1.2;
  color: $low;
  border-style: solid;
  border-color: $border;
  border-width: 0 0 1px;
  transition: color 0.5s, border 0.5s;

  &:hover {
	color: $middle;
	border-color: $middle;
  }
}

// TODO FIX STYLES