@use "../utils/constants/sizes" as *;
@use "../utils/constants/colors" as *;
@use "../utils/mixins/flex" as *;

*, *::before, *::after {
    box-sizing: border-box;
}

*::selection {
    background: $hover;
    color: white;
}

* {
    margin: 0;
    font-family: "AT Open Sans", sans-serif;
}

html, body {
    height: 100vh;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
    max-width: 100%;
}

input, button, textarea, select {
    font: inherit;
    padding: 0;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

#root {
  @include flexible(column, stretch, flex-start);
  min-width: $mini;
  min-height: 100vh;
}