@use "../../../utils/constants/colors" as *;

.disabled {
  text {
	user-select: none;
	font-weight: bold;
  }
}

.active {
  cursor: pointer;

  text {
	cursor: pointer;
	user-select: none;
	font-weight: bold;
  }

  &:hover {
	path {
	  fill: $hover;
	}

	polygon {
	  fill: $hover;
	}
  }
}