.default {
  width: 100%;
  height: 100%;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  &:after {
	content: "";
	display: block;
	width: 50px;
	height: 50px;

	border-radius: 50%;
	border-width: 4px;
	border-style: solid;
	border-color: orange transparent orange transparent;
	animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
}
