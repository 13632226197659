@use "../../../utils/mixins/flex" as *;
@use "../../../utils/constants/colors" as *;
@use "../../../utils/constants/sizes" as *;

.wrapper {
  @include flexible(column, center, center);

  position: absolute;
  left: 20px;
  top: 20px;
  gap: 10px;
  z-index: 1;

  user-select: none;

  .item {
	cursor: pointer;
	padding: 5px;
	font-size: 18px;

	background-color: white;
	border: 1px solid $low;
	transition: border 0.3s ease-in-out;
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);

	svg {
	  path {
		transition: fill 0.3s ease-in-out;
	  }
	}

	&:hover {
	  border: 1px solid $hover;

	  svg {
		path {
		  fill: $hover;
		}
	  }
	}
  }
}